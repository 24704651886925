/**
 * Avalanche Nav & Mega Nav
 * - triggers nav to appear
 */
( function() {
  const megaNavToggles     = document.querySelectorAll('.js-meganav-child-toggle');
  const megaNavContainers  = document.querySelectorAll('.js-meganav-container');
  const megaNavClose       = document.querySelectorAll('.js-meganav-close');
  const navToggle          = document.querySelector('.js-meganav-toggle');
  const navWrap            = document.querySelector('.js-meganav');
  const headerTag          = document.querySelector('.js-header');
  const navFocusableEls    = navWrap.querySelectorAll('a[href], button, select');

  const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )

  function init() {
    if (navWrap) {
      bindEvents();
    }
  }

  function bindEvents() {
    navToggle.addEventListener('click', toggleNavMenu);
    headerTag.addEventListener('keydown', handleNavKeyPress);

    for (var a = 0; a < megaNavClose.length; a++) {
      megaNavClose[a].addEventListener('click', handleCloseClick);
    }

    for (var c = 0; c < megaNavToggles.length; c++) {
      megaNavToggles[c].addEventListener('click', toggleMegaNav);
    }

    for (var e = 0; e < megaNavContainers.length; e++) {
      closeMegaNavOnClickOutside( megaNavContainers[e]);
    }
  }

  // Toggle Basic Nav Menu (via hamburger icon)
  function toggleNavMenu() {
    const isExpanded = navToggle.getAttribute('aria-expanded') === 'true' | false;

    navToggle.setAttribute('aria-expanded', !isExpanded);
    navWrap.classList.toggle('open');
    navWrap.classList.toggle('closed');
    headerTag.classList.toggle('open');
  }

  // Mega Nav Functions
  function toggleMegaNav(event) {
    event.preventDefault();

    const isExpanded  = event.target.getAttribute('aria-expanded') === 'true' | false;
    const megaNavMenu = event.target.nextElementSibling;
    const isOpen      = megaNavMenu.classList.contains('is-open');

    closeMegaNavMenus();
    if(isOpen) {
      megaNavMenu.classList.add('is-open');
    }
    event.target.setAttribute('aria-expanded', !isExpanded);
    megaNavMenu.classList.toggle('is-open');
  }

  function handleCloseClick() {
    // move focus to expandable button
    moveFocusOutsideMegaNav();

    // close the MegaNavs like usual
    closeMegaNavMenus();
  }

  function moveFocusOutsideMegaNav() {
    const currentMegaNavContainer = document.querySelector('.js-meganav-container.is-open');
    const currentMegaNavButton    = currentMegaNavContainer.previousElementSibling;

    // Check if focus is currently inside MegaNav, if so move focus to button
    if (currentMegaNavContainer.contains(document.activeElement)) {
      currentMegaNavButton.focus();
    }
  }

  function closeMegaNavMenus() {
    for (var f = 0; f < megaNavContainers.length; f++) {
      megaNavContainers[f].classList.remove('is-open');
    }
    for (var g = 0; g < megaNavToggles.length; g++) {
      megaNavToggles[g].setAttribute('aria-expanded', false);
    }
  }

  function closeMegaNavOnClickOutside(element) {
    const outsideClickListener = event => {
      if(!event.target.classList.contains('js-meganav-child-toggle')) {
        if (!element.contains(event.target) && isVisible(element)) {
          closeMegaNavMenus();
        }
      }
    }
    document.addEventListener('click', outsideClickListener)
  }

  function getLastFocusableEl() {
    // Convert Nodelist to Array
    const navFocusableElsArray = Array.prototype.slice.call(navFocusableEls);

    // Filter out any el that has a closed Container or is currently hidden
    const navFocusableElsArrayFiltered = navFocusableElsArray.filter(function(el) {
      const parentMeganavContainer = el.closest('.js-meganav-container');

      if ( window.getComputedStyle(el).display === 'none' ) {
        return false;
      }

      if ( parentMeganavContainer ) {
        return parentMeganavContainer.classList.contains('is-open');
      } else {
        return true;
      }
    })

    return navFocusableElsArrayFiltered[navFocusableElsArrayFiltered.length - 1];
  }

  function handleNavKeyPress(e) {
    const isChildNavOpen = document.querySelector('.is-open .js-meganav-close');
    const isMainNavOpen  = navWrap.classList.contains('open');

    // Escape key closes a child nav first if open, otherwise closes the main nav
    if (e.keyCode === 27 ) {
      if (isChildNavOpen) {
        moveFocusOutsideMegaNav();
        closeMegaNavMenus();
      } else if (isMainNavOpen) {
        navToggle.focus();
        toggleNavMenu();
      }
    }

    // Close main nav and child navs when leaving last focusable element in the nav
    // -- be careful not to close if Shift+Tab are pressed for backwards traversal
    if (e.key === 'Tab' & document.activeElement === getLastFocusableEl()) {
      if (e.shiftKey) {
        return;
      }

      if (isChildNavOpen) {
        closeMegaNavMenus();
      }

      if (isMainNavOpen) {
        toggleNavMenu();
      }
    }
  }

  return init();
} )();
